import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { GlobalTranslationVar } from "@app/class/GlobalTranslationVar";
import { localeNumberFormat } from "@app/helpers/number-formater";

@Pipe({
    name: "numberFormat",
    standalone: true
})
export class NumberFormatPipe implements PipeTransform {
	constructor(private sanitized: DomSanitizer) {}
	transform(value) {
		return localeNumberFormat(value);
	}
}
