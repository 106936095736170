import { Injectable } from '@angular/core';
import { generateGuid } from '@app/helpers/guid-generator';
import { TrackAttachmentDtoClass, TrackCatalogDtoClass, TrackCatalogExternalDtoClass, TrackCatalogItemDtoClass, TrackCatalogListingDtoClass, TrackCatalogSuggestionDtoClass, TrackContactFormOpenDtoClass, TrackContactFormSubmitDtoClass, TrackExportDtoClass, TrackLoginRequestDtoClass, TrackLoginSuccessDtoClass, TrackSearchCatalogDtoClass, TrackSearchGlobalDtoClass, TrackShareCatalogItemDtoClass } from '@app/models/tracking/track-dtos';
import { ITrackAttachmentParams, ITrackCatalogExternalParams, ITrackCatalogItemParams, ITrackCatalogListingParams, ITrackCatalogParams, ITrackCatalogSuggestionParams, ITrackContactFormOpenParams, ITrackContactFormSubmitParams, ITrackExportParams, ITrackExportsParams, ITrackLoginRequestParams, ITrackLoginSuccessParams, ITrackSearchCatalogParams, ITrackSearchGlobalParams, ITrackShareCatalogItemParams } from '@app/models/tracking/track-event-param-type';
import { CatalogViewOrigin, TrackAttachmentDto, TrackCatalogDto, TrackCatalogExternalDto, TrackCatalogItemDto, TrackCatalogListingDto, TrackCatalogSuggestionDto, TrackContactFormOpenDto, TrackContactFormSubmitDto, TrackExportDto, TrackLoginRequestDto, TrackLoginSuccessDto, TrackSearchCatalogDto, TrackSearchGlobalDto, TrackShareCatalogItemDto } from '@interfaces/HttpClient/AnalyticsApiTrackingModels';
import { ContactFormRequestTypes, ContactFormType } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { AccountService } from './account.service';
import { AnalyticsTrackingApplicationStateService } from './analytics-tracking-application-state.service';
import { AnalyticsTrackingHistoryService } from './analytics-tracking-history.service';
import { AnalyticsTrackingQueueService } from './analytics-tracking-queue.service';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsTrackingEventAggregatorService {

	constructor(
		private trackingQueueService: AnalyticsTrackingQueueService,
		private historyService: AnalyticsTrackingHistoryService,
		private applicationStateService: AnalyticsTrackingApplicationStateService,
		private accountService: AccountService) { }

	public trackAttachment(params: ITrackAttachmentParams) {
		const applicationState = this.applicationStateService.getState();

		const trackAttachmentDto: TrackAttachmentDto = new TrackAttachmentDtoClass({
			catalogKey: params.catalogKey,
			catalogItemGuid: params.catalogItemGuid,
			attachmentId: params.attachmentId,
			isExplicitlyViewed: params.isExplicitlyViewed,
			isLargeViewer: params.isLargeViewer,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
		})

		this.trackingQueueService.addToQueue(trackAttachmentDto);
	}

	public trackCatalog(params: ITrackCatalogParams) {
		const catalogHistoryState = this.historyService.getCatalogHistoryState()
		const postSearchEventHistory = this.historyService.getPostSearchEventHistoryState()

		// Stop CatalogViewOrigin = Administration Tracking
		if (catalogHistoryState.catalogViewOrigin === CatalogViewOrigin.Administration) {
			catalogHistoryState.catalogViewOrigin = CatalogViewOrigin.Other;
		}

		const applicationState = this.applicationStateService.getState();

		const trackCatalogDto: TrackCatalogDto = new TrackCatalogDtoClass({
			catalogKey: params.catalogKey,
			isCatalogInformation: params.isCatalogInformation,
			catalogViewOrigin: catalogHistoryState.catalogViewOrigin,
			searchResultPage: catalogHistoryState.searchResultPage,
			searchResultPosition: catalogHistoryState.searchResultPosition,
			searchGuid: postSearchEventHistory.searchGuid,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
		})

		this.trackingQueueService.addToQueue(trackCatalogDto);


		this.historyService.resetCatalogHistoryState()
	}

	public trackExport(params: ITrackExportParams) {
		const applicationState = this.applicationStateService.getState();

		const trackExportDto: TrackExportDto = new TrackExportDtoClass({
			catalogKey: params.catalogKey,
			catalogItemGuid: params.catalogItemGuid,
			attachmentId: params.attachmentId,
			exportFormat: params.exportFormat,
			source: params.source,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
		})

		this.trackingQueueService.addToQueue(trackExportDto);
	}

	public trackExports(params: ITrackExportsParams) {
		params.exports.forEach(e => this.trackExport(e))
	}

	public trackCatalogItem(params: ITrackCatalogItemParams) {
		const catalogHistoryState = this.historyService.getCatalogHistoryState()
		const postSearchEventHistory = this.historyService.getPostSearchEventHistoryState()
		const applicationState = this.applicationStateService.getState();

		const trackCatalogItemDto: TrackCatalogItemDto = new TrackCatalogItemDtoClass({
			catalogKey: params.catalogKey,
			catalogItemGuid: params.catalogItemGuid,
			catalogViewOrigin: catalogHistoryState.catalogViewOrigin,
			searchResultPage: catalogHistoryState.searchResultPage,
			searchResultPosition: catalogHistoryState.searchResultPosition,
			searchGuid: postSearchEventHistory.searchGuid,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
		})

		this.trackingQueueService.addToQueue(trackCatalogItemDto);

		this.historyService.resetCatalogHistoryState()
		// postSearchEventHistory is reset here, because a CatalogSearch process is ended by a CatalogItemView
	}

	public trackCatalogListing(params: ITrackCatalogListingParams) {
		const postSearchEventHistory = this.historyService.getPostSearchEventHistoryState()
		const applicationState = this.applicationStateService.getState();

		const trackCatalogListingDto: TrackCatalogListingDto = new TrackCatalogListingDtoClass({
			catalogKeys: params.catalogKeys.join(','),
			source: params.catalogListingSource,
			searchGuid: postSearchEventHistory.searchGuid,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
		})

		this.trackingQueueService.addToQueue(trackCatalogListingDto);

		// postSearchEventHistory is not reset here, because a catalogView could follow
	}

	public trackCatalogSuggestion(params: ITrackCatalogSuggestionParams) {
		const applicationState = this.applicationStateService.getState();
		const catalogHistoryState = this.historyService.getCatalogHistoryState()
		const trackCatalogSuggestionDto: TrackCatalogSuggestionDto = new TrackCatalogSuggestionDtoClass({
			catalogKeys: params.catalogKeys.join(","),
			source: catalogHistoryState.catalogSuggestionSource,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
		})

		this.trackingQueueService.addToQueue(trackCatalogSuggestionDto);
	}

	public trackCatalogExternal(params: ITrackCatalogExternalParams) {
		const applicationState = this.applicationStateService.getState();

		const trackCatalogExternalDto: TrackCatalogExternalDto = new TrackCatalogExternalDtoClass({
			catalogKey: params.catalogKey,
			type: params.type,
			targetUrl: params.targetUrl,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
		})

		this.trackingQueueService.addToQueue(trackCatalogExternalDto);
	}

	public trackContactFormOpen(params: ITrackContactFormOpenParams) {
		const applicationState = this.applicationStateService.getState();

		let searchGuid: string = null;
		const isSearchFeedback = [ContactFormType.CatalogSearchFeedback, ContactFormType.GlobalSearchFeedback].includes(params.type)
		if (isSearchFeedback) {
			searchGuid = this.historyService.getPostSearchEventHistoryState().searchGuid;
		}

		const trackContactFormOpenDto: TrackContactFormOpenDto = new TrackContactFormOpenDtoClass({
			catalogKey: params.catalogKey,
			catalogItemGuid: params.catalogItemGuid,
			contactFormType: params.type,
			searchGuid,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
		})

		this.trackingQueueService.addToQueue(trackContactFormOpenDto);
	}

	public trackContactFormSubmit(params: ITrackContactFormSubmitParams) {
		const applicationState = this.applicationStateService.getState();

		let searchGuid: string = null;
		const isSearchFeedback = [ContactFormType.CatalogSearchFeedback, ContactFormType.GlobalSearchFeedback].includes(params.type)
		if (isSearchFeedback) {
			searchGuid = this.historyService.getPostSearchEventHistoryState().searchGuid;
		}

		const trackContactFormSubmitDto: TrackContactFormSubmitDto = new TrackContactFormSubmitDtoClass({
			catalogKey: params.catalogKey,
			catalogItemGuid: params.catalogItemGuid,
			salutation: params.contact.salutation,
			firstName: params.contact.firstName?.trim(),
			lastName: params.contact.lastName?.trim(),
			phone: params.contact.phone?.trim(),
			companyName: params.contact.company?.trim(),
			message: params.contact.message?.trim(),
			subject: params.contact.subject?.trim(),
			professionGroup: params.contact.professionGroup,
			intentions: this.bitEncodedRequestTypesToArray(params.contact.requestTypes as number),
			contactFormType: params.type,
			searchGuid,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
		})

		this.trackingQueueService.addToQueue(trackContactFormSubmitDto);
	}

	private bitEncodedRequestTypesToArray(requestTypes: number): number[] {
		// eslint-disable-next-line no-bitwise
		return Object.keys(ContactFormRequestTypes).filter(key => isNaN(Number(ContactFormRequestTypes[key]))).map(key => +key).filter(n => n & requestTypes).map(m => 1 + Math.log2(m));
	}

	public trackLoginRequest(params: ITrackLoginRequestParams) {
		const applicationState = this.applicationStateService.getState();

		const trackLoginRequestDto: TrackLoginRequestDto = new TrackLoginRequestDtoClass({
			source: params.source,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			attachmentId: params.attachmentId,
			catalogItemGuid: params.catalogItemGuid,
			catalogKey: params.catalogKey,
			trackTimestamp: new Date(),
		})

		this.trackingQueueService.addToQueue(trackLoginRequestDto);
	}

	public trackLoginSuccess(params: ITrackLoginSuccessParams) {
		const applicationState = this.applicationStateService.getState();
		const account = this.accountService.getAccount();

		const claims = account?.idTokenClaims as any;
		const trackLoginSuccessDto: TrackLoginSuccessDto = new TrackLoginSuccessDtoClass({
			source: applicationState.loginRequestSource,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
			professionGroup: claims?.extension_Professiongroup ? +claims?.extension_Professiongroup : null
		})
		this.trackingQueueService.addToQueue(trackLoginSuccessDto);
	}

	public trackSearchGlobal(params: ITrackSearchGlobalParams) {
		const searchHistoryState = this.historyService.getSearchHistoryState()
		const applicationState = this.applicationStateService.getState();

		const trackSearchGlobalDto: TrackSearchGlobalDto = new TrackSearchGlobalDtoClass({
			origin: searchHistoryState.searchOrigin,
			suggestionSource: searchHistoryState.searchSuggestionSource,
			searchText: params.searchText,
			searchFilter: params.searchFilter,
			pageIndex: params.pageIndex,
			pageSize: params.pageSize,
			countCatalogs: params.countCatalogs,
			countCatalogItems: params.countCatalogItems,
			searchGuid: params.searchGuid,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
		});

		this.trackingQueueService.addToQueue(trackSearchGlobalDto);

	}

	public trackSearchCatalog(params: ITrackSearchCatalogParams) {
		const searchHistoryState = this.historyService.getSearchHistoryState()
		const applicationState = this.applicationStateService.getState();

		const trackSearchCatalogDto: TrackSearchCatalogDto = new TrackSearchCatalogDtoClass({
			catalogKey: params.catalogSearchRequest.catalogKey,
			searchText: params.catalogSearchRequest.searchTerm,
			searchFilter: params.catalogSearchRequest.searchFilter,
			pageIndex: params.catalogSearchRequest.pageIndex,
			pageSize: params.catalogSearchRequest.pageSize,
			countCatalogItems: params.countCatalogItems,
			searchGuid: params.searchGuid,
			origin: searchHistoryState.searchOrigin,
			suggestionSource: searchHistoryState.searchSuggestionSource,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
		});

		this.trackingQueueService.addToQueue(trackSearchCatalogDto);

	}

	public trackShareCatalogItem(params: ITrackShareCatalogItemParams) {
		const applicationState = this.applicationStateService.getState();

		const trackShareCatalogItemDto: TrackShareCatalogItemDto = new TrackShareCatalogItemDtoClass({
			catalogKey: params.catalogKey,
			catalogItemGuid: params.catalogItemGuid,
			shareCatalogItemType: params.shareCatalogItemType,
			userId: applicationState.userId,
			sessionId: applicationState.sessionId,
			authenticatedUserId: applicationState.authenticatedUserId,
			interface: applicationState.interface,
			referrer: applicationState.referrer,
			integrationKey: applicationState.integrationKey,
			applicationKey: applicationState.applicationKey,
			hostAppUserAgent: applicationState.hostAppUserAgent,
			guid: generateGuid(),
			trackTimestamp: new Date(),
		})

		this.trackingQueueService.addToQueue(trackShareCatalogItemDto);
	}
}
