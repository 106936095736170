import { Inject, Injectable, inject } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { firstValueFrom } from "rxjs";
import { isNullish } from "../helpers/math-utils";
import { ICommonEnvironment } from "../interfaces/iCommonEnvironment";

@Injectable({
	providedIn: 'root'
})
export class MsalB2CService {

	private authService: MsalService;
	constructor(
		@Inject('environment') private environment: ICommonEnvironment
	) {
		this.authService = inject(MsalService)
	}



	public async signup(loginLanguage: string): Promise<void | AuthenticationResult> {
		if (!isNullish(this.environment?.msal) && !isNullish(this.environment?.msal?.signUpFlow)) {
			return this.invokeFlow(this.environment.msal.signUpFlow, loginLanguage);
		}
		throw new Error('environment not set');
	}

	public async login(loginLanguage: string): Promise<void | AuthenticationResult> {
		if (!isNullish(this.environment?.msal) && !isNullish(this.environment?.msal?.signInFlow)) {
			return this.invokeFlow(this.environment.msal.signInFlow, loginLanguage);
		}
		throw new Error('environment not set');
	}

	public async logout(): Promise<void | AuthenticationResult> {
		return this.invokeFlow('logout', 'logout');
	}

	public async editUserProfile(loginLanguage: string): Promise<void | AuthenticationResult> {
		if (!isNullish(this.environment?.msal) && !isNullish(this.environment?.msal?.profileEditFlow)) {
			return this.invokeFlow(this.environment.msal.profileEditFlow, loginLanguage);
		}
	}

	public async resetPwd(loginLanguage: string): Promise<void | AuthenticationResult> {
		if (!isNullish(this.environment?.msal) && !isNullish(this.environment?.msal?.passwordResetFlow)) {
			return this.invokeFlow(this.environment.msal.passwordResetFlow, loginLanguage);
		}
	}


	public isLoggedIn(): boolean {
		return this.authService.instance.getAllAccounts().length > 0;
	}

	public acquireTokenSilent(scopesUrls: string[]): Promise<AuthenticationResult> {

		const account = this.getAllAccounts()[0];
		return firstValueFrom(this.authService.acquireTokenSilent({ account, scopes: scopesUrls }));
	}


	public async acquireIdTokenSilent(): Promise<AuthenticationResult> {
		if (!isNullish(this.environment?.msal) && !isNullish(this.environment?.msal?.clientId)) {
			return this.acquireTokenSilent([this.environment.msal.clientId]);
		}
		throw new Error('environment not set');

	}

	public async isLoggedInByAcquireTokenSilent() {
		await firstValueFrom(this.authService.initialize());
		await firstValueFrom(this.authService.handleRedirectObservable()); // This will no longer throw this error since initialize completed before this was invoked
		return this.acquireIdTokenSilent();
	}

	public getAllAccounts(): AccountInfo[] {
		return this.authService.instance.getAllAccounts();
	}

	private async invokeFlow(flowName: string, loginlanguage?: string): Promise<void | AuthenticationResult> {

		if (flowName === 'logout') {
			return firstValueFrom(this.authService.logout());
		}
		if (!isNullish(this.environment?.msal)) {
			const authority = `${"https://" + this.environment.msal.tenant + ".b2clogin.com/" + this.environment.msal.tenant + ".onmicrosoft.com/"}${flowName}`;
			return firstValueFrom(this.authService.loginRedirect({
				extraQueryParameters: { ui_locales: loginlanguage ?? "" },
				authority,
				scopes: this.getScopes()
			}));
		} else {
			throw new Error('environment not set');
		}
	}



	private getScopes(): string[] {
		if (!isNullish(this.environment?.msal)) {
			return [`https://${this.environment.msal.tenant}.onmicrosoft.com/${this.environment.msal.middlewareAppIdUriSuffix}/${this.environment.msal.apiAccessScope}`];
		} else {
			throw new Error('environment not set');
		}
	}


	public async getAccessToken(): Promise<string> {
		try {
			const result = await this.acquireTokenSilent(this.getScopes());

			return result.accessToken;
		}
		catch {
			return '';
		}
	}

}
